"use client";
import { useContext, useEffect, useRef, useState } from "react";
import Script from "next/script";
import { usePathname } from "next/navigation";
import { PlayerVodV2Type } from "./types/PlayerVodV2";
import { genUniqIDVideoPlayer } from "@/app/lib/video/videoTools";
import { getAttributesVideoVod } from "@/app/helpers/video/getVideoAttributes";
import Video from "@/app/components/atoms/Video/Video";
import VideoVertical from "@/app/components/atoms/Video/VideoVertical";
import ReactDOM from "react-dom";
import { ToggleMuteContext } from "@/app/context/MutePlayerContext";
import { createErrorMesageHTML } from "@/app/helpers/video/createErrorMessageHTML";

declare let window: any;

function initPlayer(
	idPlayer: string,
	mute: boolean,
	idVideo: string,
	type: string,
	autoplay: boolean,
	controls: boolean,
	loop: boolean,
	path: any,
	cmsid?: boolean,
	options?: any
) {
	window?.VideoSDK?.AMP?.playVOD(
		getAttributesVideoVod(
			idPlayer,
			mute,
			idVideo,
			type,
			autoplay,
			controls,
			loop,
			path,
			options
		)
	);
}

export default function PlayerVodV2({
	idVideo = "",
	type = "clips",
	mute = false,
	autoplay = false,
	manualPlay = false,
	manualPause = false,
	manualUnmute = false,
	controls = true,
	loop = false,
	cmsid = false,
	manualVodId = "",
	onVideoEnded = Function,
	currentPlaceholder = "",
	prevVod = undefined,
	isVerticalVideo,
}: PlayerVodV2Type) {
	const videoRef = useRef<HTMLDivElement>(null);
	const { isMute, setIsMute } = useContext(ToggleMuteContext);
	ReactDOM.preconnect("https://player-assets.nmas.com.mx/", {
		crossOrigin: "anonymous",
	});
	const [sdkLoaded, setSDKLoaded] = useState(false);
	const [playerDiv, setPlayerDiv] = useState<any>(null);
	const [playerID, setPlayerID] = useState<any>("");
	const pagePath = usePathname();
	const [idPlayer] = useState<any>(
		manualVodId !== "" ? `player-${manualVodId}` : genUniqIDVideoPlayer()
	);
	const createPlayer = () => {
		let timeout: any;
		if (cmsid) {
			if (isVerticalVideo) {
				setPlayerID(idPlayer);
				setPlayerDiv(VideoVertical(idPlayer, currentPlaceholder));
			} else {
				setPlayerID(idPlayer);
				setPlayerDiv(Video(idPlayer, currentPlaceholder));
			}
		}

		if (
			!window?.VideoSDK?.AMP?.getAMPlayerInstance(
				cmsid ? idPlayer : `player-${idVideo}`
			)
		) {
			if (!window?.VideoSDK) {
				const options = isVerticalVideo
					? {
							fullscreenOrientationLock: "portrait",
							aspectratio: "9:16",
					  }
					: undefined;
				document?.addEventListener("AMPReady", () => {
					window?.VideoSDK?.AMP?.playVOD(
						initPlayer(
							cmsid ? idPlayer : `player-${idVideo}`,
							mute,
							idVideo,
							type,
							autoplay,
							controls,
							loop,
							pagePath,
							undefined,
							options
						)
					);
					if (onVideoEnded !== undefined) {
						window?.VideoSDK?.AMP?.on("ended", () => {
							onVideoEnded();
						});
					}
				});
			} else {
				const options = isVerticalVideo
					? { fullscreenOrientationLock: "portrait", aspectratio: "9:16" }
					: undefined;

				timeout = setTimeout(
					() => {
						initPlayer(
							cmsid ? idPlayer : `player-${idVideo}`,
							mute,
							idVideo,
							type,
							autoplay,
							controls,
							loop,
							pagePath,
							undefined,
							options
						);
						if (onVideoEnded !== undefined) {
							window?.VideoSDK?.AMP?.on("ended", () => {
								onVideoEnded();
							});
						}
						window?.VideoSDK?.AMP?.on("ended", () => {
							onVideoEnded();
						});
						window?.VideoSDK?.AMP?.on("muted", () => {
							setIsMute(true);
						});
						window?.VideoSDK?.AMP?.on("unmuted", () => {
							setIsMute(false);
						});
						window.VideoSDK.AMP.on(
							"showPoster",
							(e: { action: string; identifier: string }) => {
								if (e.action === "show" && e.identifier === "GeoblockWarning") {
									if (videoRef.current && videoRef.current) {
										videoRef.current.appendChild(
											createErrorMesageHTML(e.identifier)
										);
									}
								}
							}
						);
					},
					pagePath === "/descubre" ? 100 : 500
				);
			}
		} else {
			window?.VideoSDK?.AMP?.getAMPlayerInstance(
				`player-${manualVodId !== "" ? manualVodId : idVideo}`
			)?.play();

			window?.VideoSDK?.AMP?.getAMPlayerInstance(
				`player-${manualVodId !== "" ? manualVodId : idVideo}`
			)?.setMute(isMute);
		}

		return () => {
			clearTimeout(timeout);
			window?.VideoSDK?.AMP?.off("ended");
		};
	};

	const initSDK = () => {
		if (typeof globalThis.window !== "undefined") {
			window.VideoSDK.AMP.init({
				vendor: "jw",
				loglevel: "debug",
				connection: {
					env: "prod",
					profileId: "c09ce787-4010-4bdf-96ad-fe785b6eca50",
				},
			});
			setSDKLoaded(() => {
				return window.VideoSDK.AMP.ready;
			});
		}
	};

	useEffect(() => {
		if (manualPlay) {
			if (window?.VideoSDK) {
				window?.VideoSDK?.AMP?.unmute(playerID);
				window?.VideoSDK?.AMP?.play(playerID);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [manualPlay]);

	useEffect(() => {
		if (manualPause) {
			if (window?.VideoSDK) {
				window?.VideoSDK?.AMP?.pause(playerID);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [manualPause]);

	useEffect(() => {
		if (manualUnmute) {
			if (window?.VideoSDK) {
				window?.VideoSDK?.AMP?.unmute(playerID);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [manualUnmute]);

	useEffect(() => {
		if (sdkLoaded) {
			createPlayer();
		}

		// VERIFICA QUE EL SCRIPT YA ESTÉ CARGADO Y EL SDK INICIALIZADO
		// FUNCIONA PARA LAS NAVEGACIONES SUBSECUENTES
		if (typeof globalThis.window.VideoSDK !== "undefined") {
			createPlayer();
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sdkLoaded, idVideo]); // END EFFECT

	useEffect(() => {
		if (idVideo !== prevVod) {
			window?.VideoSDK?.AMP?.destroy(`player-${prevVod}`);
			if (window?.VideoSDK?.AMP?.getAMPlayerInstance(`player-${prevVod}`)) {
				if (cmsid) {
					setPlayerDiv(Video(idPlayer, currentPlaceholder));
				} else {
					setPlayerDiv(<div id={`player-${idVideo}`} />);
				}
			} else {
				if (cmsid) {
					if (!isVerticalVideo) {
						setPlayerDiv(Video(idPlayer, currentPlaceholder));
					}
				} else {
					setPlayerDiv(<div id={`player-${idVideo}`} />);
				}
			}
		} else {
			if (cmsid) {
				setPlayerDiv(Video(idPlayer, currentPlaceholder));
			} else {
				setPlayerDiv(<div id={`player-${idVideo}`} />);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [idPlayer, idVideo, prevVod]);
	useEffect(() => {
		let to: any = null;
		// Set the mute state of the player
		const playerInstance = window?.VideoSDK?.AMP?.getAMPlayerInstance(
			`player-${idVideo}`
		);
		if (playerInstance) {
			playerInstance.setMute(isMute);
		} else {
			to = setTimeout(() => {
				const delayedPlayerInstance =
					window?.VideoSDK?.AMP?.getAMPlayerInstance(`player-${idVideo}`);
				if (delayedPlayerInstance) {
					delayedPlayerInstance.setMute(isMute);
				}
			}, 2000);
		}

		return () => clearTimeout(to);
	}, [idVideo, isMute]);

	return (
		<>
			<Script src={process.env.NEXT_PUBLIC_SCRIPT_PLAYER} onLoad={initSDK} />
			<div
				ref={videoRef}
				className="wrapperVideo"
				style={{ position: "relative" }}
			>
				{playerDiv}
			</div>
		</>
	);
}
